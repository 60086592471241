// 站点信息
export const webinfo = {
    name: '王者生活商城管理平台',
    spimpleName: '王者生活',
    company: '卡尔斯特酒业'
}

// 正式服	sys.vivreking.com 9.7已打包
export const imgapi = "https://kingwine.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
export const api = "https://sys.vivreking.com/api/admin/";
export const appapi = "https://sys.vivreking.com/api/";
export const uploadApi = "https://sys.vivreking.com/api/upload_img";
export const delOssApi = "https://sys.vivreking.com/api/del_img/";


// // 测试服API地址
// export const imgapi = "https://kingwine.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
// export const api = "https://testsys.vivreking.com/api/admin/";
// export const appapi = "https://testsys.vivreking.com/api/";
// export const uploadApi = "https://testsys.vivreking.com/api/upload_img";
// export const delOssApi = "https://testsys.vivreking.com/api/del_img/";

// 腾讯地图Key
export const txMapKey = "BPQBZ-JOHWT-AQWXK-VTCIX-U6P67-PVFR5";